<template>
<div class="admin-container" v-loading.fullscreen.lock="loading">
    <div class="admin-container-header">
        <div class="flex justify-between">
            <h1> Акции</h1>
            <div class="flex items-center">
                <el-input placeholder="Type something" suffix-icon="el-icon-search" v-model="input2"> </el-input>
                <button class="button ml-10" @click="$router.push('/admin/stocks/add')"> Создать</button>
            </div>
        </div>
    </div>
    <div class="admin-table">
        <el-table :data="StockList" style="width: 100%;" default-expand-all>
            <el-table-column prop="title_ua" label="Title" class="table-pl-20px">
            </el-table-column>
            <el-table-column prop="start_date" label="Дата відкриття" width="180">
            </el-table-column>
            <el-table-column label="Дії" width="180" class="table-pr-20px ">
                <template slot-scope="scope">
                    <div class="flex"> 
                        <el-button  @click="$router.push(`/admin/stocks/edit/${scope.row.id}`)"  slot="reference">Edit</el-button>
                        <el-popconfirm class="ml-10" @confirm="_DELETE(scope.row.id)" confirm-button-text='OK' cancel-button-text='No, Thanks' icon="el-icon-info" icon-color="red" title="Are you sure to delete this?">
                            <el-button class="delete" slot="reference" >Delete</el-button>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="admin-poginations">
        <el-pagination :page-sizes="[10, 20, 50, 100]" :page-size="page_size" @current-change="paginate"   :current-page.sync="current_page" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            input2: '',
            current_page: Number(1),
            page_count: 1,
            page_size: 10,
            loading: false,
            tableData: [{
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
                {
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
                {
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
                {
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
                {
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
                {
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
                {
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
                {
                    title: 'Кредит під заставу аграрних розписок',
                    date: '18.10 - 28.10.2021'
                },
            ],
            StockList: []
        }

    },
    computed: {
        me() {
            return this.$store.state.me
        }
    },
    watch: {
        me(val) { 
            if (val.role_id != 1) {
                this.$router.push('/')
            }
        },
     
    },
    methods: { 
        GetStock() {
            this.loading = true
            
            if( this.$route.query.page!=this.current_page ){
                 this.$router.push(`/admin?page=${this.current_page}`)
            }
            this.axios.get(`/admin/stock?page=${this.current_page}`).then(res => {
                    this.StockList = res.data.data.data 
                    this.page_count = parseInt(res.data.data.total / this.page_size)
                    if (res.data.data.total % this.page_size > 0) {
                        this.page_count = this.page_count + 1
                    }
                    this.current_page= this.$route.query.page ?  Number(this.$route.query.page):Number(1) 
                })
                .finally(() => {
                    this.loading = false
                })
        },
       _DELETE(i){
           this.loading=true
           this.axios.delete(`/admin/stock/${i}`).then(res=>{ console.log(res); this.GetStock() })
       },
        paginate(val) {
            this.current_page =Number(val);
            this.GetStock()
        },
      
    },
    mounted() {
        this.current_page= this.$route.query.page ?  Number(this.$route.query.page) :Number(1)
        this.GetStock()
    }
}
</script>

<style>
.admin-container {
    background: var(--white-bg);
    min-height: 100%;
    width: 100%;

}

.admin-container-header {
    padding: 20px;
}

.admin-table {
    width: 100%;
}

.admin-poginations {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
